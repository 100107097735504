/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --orangeAccent: #ff9100;
    --grayAccent: #f5f4f3;
    --footerHeaderHeight: 80px;
    --footerHeaderHeightMobile: 60px;
    --scrollbarWidth: 12px;
    --mobileMenuHeight: 230px;
}

html, body {
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
}

body {
    margin: 0;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
h1{
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 300 !important;
}
/* width */
::-webkit-scrollbar {
  width: var(--scrollbarWidth) !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}
::-webkit-scrollbar-corner { 
  background:transparent !important
 }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a7a7a7 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
app-layout-selection {
  display: flex;
  flex-direction: column;
}

app-wall-color-config {
  display: flex;
  flex-direction: column;
}
